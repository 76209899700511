body {
  background-color: white;
}

small.ex {
  color: red;
}

div.center {
  text-align: center;
}

.alert-dialog {
  text-align: center;
  padding: 50px;
}
