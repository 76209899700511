.secret-display-header {
  background-color: #556a76;
}

.default-logo {
  padding: 15px;
}

.alert-info-dialog {
  text-align: center;
  width: 50%;
  margin: 10px;
}

.center {
  margin: auto;
  width: 50%;
  padding: 10px;
}
